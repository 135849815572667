import React, { useState } from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import { Link } from "gatsby"
import axios from "axios"
import { FormattedMessage } from "gatsby-plugin-intl"

export default function CoachPage() {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    agreedTerms: false,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      ...serverState,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ ...serverState, submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/ff85151a-ac4f-4aa6-abbd-49c2ba5fa4cc",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Díky!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <Layout>
      <Header />
      <section className="page">
        <div className="container">
          <h1>Mám zájem být živlovým průvodcem</h1>
          <form onSubmit={handleOnSubmit}>
            <div className="form-inputs">
              <div>
                <label htmlFor="name">Jméno</label>
                <input
                  type="text"
                  name="name"
                  // placeholder="Vladimír"
                  id="name"
                />
              </div>
              <div>
                <label htmlFor="surname">Přijmení</label>
                <input
                  type="text"
                  name="surname"
                  // placeholder="Jelínek"
                  id="surname"
                />
              </div>
              <div>
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  name="email"
                  // placeholder="chci@zivlovka.cz"
                  id="email"
                />
              </div>
            </div>
            <div className="form-msg">
              <textarea name="msg" rows="5" placeholder="Vaše zpráva..." />
            </div>
            <label htmlFor="agree" className="agree">
              <input
                type="checkbox"
                name="agree"
                id="agree"
                defaultChecked={serverState.agreedTerms}
                onChange={() => {
                  setServerState({
                    ...setServerState,
                    agreedTerms: !serverState.agreedTerms,
                  })
                }}
              />
              <FormattedMessage id="agree_with" defaultMessage="Souhlasím se" />{" "}
              <Link to="/terms">
                <FormattedMessage
                  id="agree_processing_data"
                  defaultMessage="zpracováním údajů"
                />
              </Link>
            </label>
            <button
              type="submit"
              className="btn"
              disabled={!serverState.agreedTerms}
            >
              Odeslat
            </button>

            {serverState.status && (
              <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
              </p>
            )}
          </form>
        </div>
      </section>
    </Layout>
  )
}
